const animItems = document.querySelectorAll('.first-animation');
const animOutlinesTemporary = document.querySelectorAll('.information-main__wrapper .outline__temporary');

if (animItems.length > 0) {
    setTimeout(() => {showDisplay()}, 800)
}

function showDisplay() {
    for (let index = 0; index < animItems.length; index++) {
        animItems[index].classList.add('_animation')
    }

    for (let index = 0; index < animOutlinesTemporary.length; index++) {
        if (index === 0) {
            animOutlinesTemporary[index].classList.add('outline__rotate--m3')
            animOutlinesTemporary[index].classList.add('outline__color--pink')
            animOutlinesTemporary[index].classList.add('outline__color')
            animOutlinesTemporary[index].classList.add('outline')
        }

        if (index === 1) {
            animOutlinesTemporary[index].classList.add('outline__rotate--p1_1')
            animOutlinesTemporary[index].classList.add('outline__color--purple')
            animOutlinesTemporary[index].classList.add('outline__color')
            animOutlinesTemporary[index].classList.add('outline')
        }
    }
}
