const [first, second] = document.querySelectorAll('.lines__item')
let first_count = 25
let second_count = 25
document.addEventListener('scroll', (e) => {

    if (first_count === 15) {
        first_count = 25
    }

    if (second_count === 35) {
        second_count = 25
    }

    first_count -= 0.01
    second_count += 0.01

    first.style.transform = `translate(-${first_count}%, 0)`;
    second.style.transform = `translate(-${second_count}%, 0)`;
})